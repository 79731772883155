
import useAuth from "@/services/auth/useAuth";
import useAppConfig from "@core/app-config/useAppConfig";
import { BOverlay } from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "EmbedEmplacamento",
  components: {
    BOverlay,
  },
})
export default class EmbedEmplacamento extends Vue {
  // Data
  overlayShow = true;
  opacity = 1;
  blur = "2px";

  useAppConfig = useAppConfig();
  $route: any;

  // Computeds
  get url(): string {
    let params = "";

    if (this.$route.path == "/reports/daily-report") {
      params = `?token=${this.$route.query.token}`;
      if (typeof this.$route.query.token === "string")
        useAuth.createCookie("BoletimToken", this.$route.query.token, 1);
    }

    return (
      process.env.VUE_APP_BASE_URL_EMP + this.$route.meta.embedUrl + params
    );
  }

  get variant(): "light" | "dark" {
    return useAppConfig().skin.value;
  }

  get isDark() {
    return this.variant === "dark";
  }

  @Watch("url")
  enabledLoading() {
    this.overlayShow = true;
  }

  // Methods
  disabledLoading() {
    this.overlayShow = false;
    this.toggleDarkMode();
  }

  toggleDarkMode() {
    const iframe: HTMLIFrameElement | null = document.querySelector(
      "#embed-emplacamento iframe"
    );

    if (iframe) {
      // @ts-ignore
      iframe.contentWindow.postMessage({ isDark: this.isDark }, "*");
    }
  }
}
